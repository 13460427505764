exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-automate-repetitive-devops-tasks-tsx": () => import("./../../../src/pages/automate-repetitive-devops-tasks.tsx" /* webpackChunkName: "component---src-pages-automate-repetitive-devops-tasks-tsx" */),
  "component---src-pages-blog-setting-up-next-js-on-upsun-tsx": () => import("./../../../src/pages/blog/setting-up-next-js-on-upsun.tsx" /* webpackChunkName: "component---src-pages-blog-setting-up-next-js-on-upsun-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-clone-preview-environments-from-prod-tsx": () => import("./../../../src/pages/clone-preview-environments-from-prod.tsx" /* webpackChunkName: "component---src-pages-clone-preview-environments-from-prod-tsx" */),
  "component---src-pages-deliver-faster-tsx": () => import("./../../../src/pages/deliver-faster.tsx" /* webpackChunkName: "component---src-pages-deliver-faster-tsx" */),
  "component---src-pages-django-apps-tsx": () => import("./../../../src/pages/django-apps.tsx" /* webpackChunkName: "component---src-pages-django-apps-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-flask-apps-tsx": () => import("./../../../src/pages/flask-apps.tsx" /* webpackChunkName: "component---src-pages-flask-apps-tsx" */),
  "component---src-pages-flexible-developer-experience-tsx": () => import("./../../../src/pages/flexible-developer-experience.tsx" /* webpackChunkName: "component---src-pages-flexible-developer-experience-tsx" */),
  "component---src-pages-free-your-dev-team-tsx": () => import("./../../../src/pages/free-your-dev-team.tsx" /* webpackChunkName: "component---src-pages-free-your-dev-team-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-iterate-merge-sync-tsx": () => import("./../../../src/pages/iterate-merge-sync.tsx" /* webpackChunkName: "component---src-pages-iterate-merge-sync-tsx" */),
  "component---src-pages-laravel-apps-tsx": () => import("./../../../src/pages/laravel-apps.tsx" /* webpackChunkName: "component---src-pages-laravel-apps-tsx" */),
  "component---src-pages-make-data-driven-decisions-tsx": () => import("./../../../src/pages/make-data-driven-decisions.tsx" /* webpackChunkName: "component---src-pages-make-data-driven-decisions-tsx" */),
  "component---src-pages-manage-traffic-surges-tsx": () => import("./../../../src/pages/manage-traffic-surges.tsx" /* webpackChunkName: "component---src-pages-manage-traffic-surges-tsx" */),
  "component---src-pages-nodejs-apps-tsx": () => import("./../../../src/pages/nodejs-apps.tsx" /* webpackChunkName: "component---src-pages-nodejs-apps-tsx" */),
  "component---src-pages-pricing-calculator-tsx": () => import("./../../../src/pages/pricing/calculator.tsx" /* webpackChunkName: "component---src-pages-pricing-calculator-tsx" */),
  "component---src-pages-pricing-index-tsx": () => import("./../../../src/pages/pricing/index.tsx" /* webpackChunkName: "component---src-pages-pricing-index-tsx" */),
  "component---src-pages-startup-promotion-tsx": () => import("./../../../src/pages/startup-promotion.tsx" /* webpackChunkName: "component---src-pages-startup-promotion-tsx" */),
  "component---src-pages-symfony-apps-tsx": () => import("./../../../src/pages/symfony-apps.tsx" /* webpackChunkName: "component---src-pages-symfony-apps-tsx" */),
  "component---src-pages-try-it-now-tsx": () => import("./../../../src/pages/try-it-now.tsx" /* webpackChunkName: "component---src-pages-try-it-now-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

